<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{ $t("message.set_address") }}</p>
          <div>
            <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div v-loading="loading" class="timeline-items__right rounded-sm w-100 p-4">
            <h3> {{ $t('message.reciever') }}: {{ reciever.name }} </h3>
            <h4 v-if="selectedAddress.deal_address"> {{ $t('message.address') }}: {{ selectedAddress.deal_address.text }} </h4>
            
            <div>
                <span>{{ $t('message.reciever_deals') }}</span>   
                <el-table
                    ref="dealList"
                    header-row-class-name="header__class"
                    row-class-name="row__class"
                    :data="reciever.deals"
                    stripe border
                    style="width: 100%"
                    @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column type="index" width="55"></el-table-column>
                        <el-table-column :label="$t('message.deals')" width="120">
                            <template slot-scope="scope">{{ scope.row.id }}</template>
                        </el-table-column>
                        <el-table-column :label="$t('message.from_filial')">
                            <template slot-scope="scope">{{ scope.row.from_filial }}</template>
                        </el-table-column>
                        <el-table-column :label="$t('message.to_filial')">
                            <template slot-scope="scope">{{ scope.row.to_filial }}</template>
                        </el-table-column>
                        <el-table-column :label="$t('message.direction')">
                            <template slot-scope="scope">{{ scope.row.direction }}</template>
                        </el-table-column>
                </el-table>
            </div>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
    mixins: [form, drawer],
    props: {
        reciever_id: {
            default: null
        },
        deal_id: {
            default: null
        },
        selectedAddress: {
            default: Object
        }
    },
    data() {
        return {
            selectedDeals: []
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            reciever: "clients/recieverDealsList",
        }),
    },
    methods: {
        ...mapActions({
            updateRecieverDealsList: "clients/recieverDealsList",
        }),
        afterOpen(){
            this.loading = true;
            this.updateRecieverDealsList({id: this.reciever_id, deal_id: this.deal_id})
                .then(res => {
                    this.loading = false;
                    if(this.$refs.dealList && this.reciever.deals){
                        setTimeout(() => {
                            let deal = this.reciever.deals.find(el => el.id == this.deal_id)
                            this.$refs.dealList.toggleRowSelection(deal, true);
                        }, 100);
                    }

                }).catch(err => {
                    this.loading = false;
                });
        },
        handleSelectionChange(val) {
            this.selectedDeals = val;
        },
        submit(close = true) {
            if(this.selectedDeals && this.selectedDeals.length > 0){
                this.selectedDeals = this.selectedDeals.map(el => el.id);
                this.$emit('input', this.selectedDeals);
                if(close) this.close();
            }
        },
    },
};
</script>
