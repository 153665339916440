<template >
    <div>
      <div class="app-modal__box">
        <div class="app-modal__in">
          <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0">CDEK SENDER FORM</p>
            <div>
              <crm-store-update-close
                  :permission="$options.name"
                  :button_type="'store'"
                  :loading="loadingButton"
                  @c-submit="submit(true)"
                  @c-close="close()"
              ></crm-store-update-close>
            </div>
          </div>
        </div>
        <!-- app-modal__header end -->
  
        <div class="app-modal__body p-5 pb-0">
          <div v-loading="loading" class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="sender" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                      prop="sender_name"
                      :label="$t('message.full_name')"
                      >
                        <el-input
                            :placeholder="$t('message.full_name')"
                            v-model="sender.sender_name"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="sender_phone"
                      :label="$t('message.phone')"
                      >
                        <el-input
                            :placeholder="$t('message.phone')"
                            v-model="sender.sender_phone"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      prop="sender_email"
                      :label="$t('message.email')"
                      >
                        <el-input
                            :placeholder="$t('message.email')"
                            v-model="sender.sender_email"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                </el-row>
            </el-form>
          </div>
        </div>
        <!-- end app-modal__body -->
      </div>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
    mixins: [form, drawer],
    props: {
      party_id: {
          default: null
      },
      partyProduts: {
          default: Array
      },
    },
    data() {
        return {
            sender: {
                sender_name: 'ИМ-РК-ALM37-1',
                sender_phone: '+77087224392',
                sender_email: '',
            },
            rules: {
                sender_name: [
                  { required: true, message: this.$t('message.please_enter_input', {input: this.$t('message.full_name')}), trigger: 'change' },
                ],
                sender_phone: [
                  { required: true, message: this.$t('message.please_enter_input', {input: this.$t('message.phone')}), trigger: 'change' },
                ],
            }
        };
    },
    created() {},
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
        store: "cdek/store",
        }),
        afterOpen(){
        },
        submit(close = true) {
            let items = this.partyProduts.map(el => {
                return {
                    id: el.id,
                    type: el.type,
                    reciever_id: Array.isArray(el.reciever_id) ? el.reciever_id[0] : el.reciever_id,
                    address_id: Array.isArray(el.address_id) ? el.address_id[0] : el.address_id,
                    delivery_deal_id: el.delivery_deal_id,
                    incoming_quantity: el.incoming_quantity,
                }
            })
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.store({party_id: this.party_id, ...this.sender, items: items})
                        .then(res => {
                            this.$alert(res);
                            this.loadingButton = false;
                            this.parent().
                            this.close().listChanged();                            
                        }).catch(err => {
                            this.$alert(err);
                            this.loadingButton = false;
                            this.close();                            
                        });
                }
            })
        },
    },
};
</script>
  